<template>
  <BaseLayout :championships="championships" is-page-for-default-championship>
    <BasePage id="referee-details" title="Detalhes do Árbitro">
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="block-content-details has-background-dark">
            <div class="columns">
              <!-- <div class="column is-narrow">
              <div class="emb-user-1"></div>
            </div> -->
              <img
                v-if="$mq !== 'sm'"
                src="@/assets/icon-apito-branco.png"
                width="50px"
                style="margin: 5px;"
              />
              <div class="column is-narrow">
                <div class="centralize" style="height: 100%">
                  <h2 v-if="$mq == 'sm'" class="title-name">{{ apelido }}</h2>
                  <h2 v-else class="title-name">{{ arbitro }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="block-content-details has-background-dark">
            <h3 class="title" style="margin-top: 0; margin-bottom: 1em">
              Próxima Partida
            </h3>

            <b-table
              id="partida"
              key="partida"
              :data="partida"
              detailed
              sticky-header
              height="auto"
              :mobile-cards="false"
            >
              <template slot-scope="props">
                <b-table-column
                  :field="'nm_clube_' + props.row.mando"
                  label="Clube"
                  :sticky="true"
                >
                  <div v-if="$mq == 'sm'" style="width: max-content;">
                    <span v-if="props.row.cd_clube_casa">
                      {{
                        props.row.mando == "casa"
                          ? props.row.cd_clube_casa
                          : props.row.cd_clube_fora
                      }}
                    </span>
                    <span v-else>
                      {{
                        props.row.mando == "casa"
                          ? props.row.nm_clube_casa
                          : props.row.nm_clube_fora
                      }}
                    </span>
                  </div>
                  <div v-else style="width: max-content;">
                    <div style="display: inline-table; vertical-align: middle;">
                      <img
                        v-if="props.row.mando == 'casa'"
                        :src="props.row.escudo_clube_casa"
                        width="35"
                        alt=""
                      />
                      <img
                        v-if="props.row.mando == 'fora'"
                        :src="props.row.escudo_clube_fora"
                        width="35"
                        alt=""
                      />
                    </div>
                    <div
                      style="display: inline-table; vertical-align: middle; margin-left: 5px;"
                    >
                      {{
                        props.row.mando == "casa"
                          ? props.row.nm_clube_casa
                          : props.row.nm_clube_fora
                      }}
                    </div>
                  </div>
                </b-table-column>

                <b-table-column
                  :field="'qtd_jogos_total_clube_' + props.row.mando"
                  label="Jogos"
                  centered
                >
                  {{
                    props.row.mando == "casa"
                      ? props.row.qtd_jogos_total_clube_casa
                      : props.row.qtd_jogos_total_clube_fora
                  }}
                </b-table-column>

                <b-table-column
                  :field="'FC_jogo_total_clube_' + props.row.mando"
                  :label="$mq == 'sm' ? 'FC/ jogo' : 'FC / jogo'"
                  centered
                >
                  {{
                    props.row.mando == "casa"
                      ? props.row.FC_jogo_total_clube_casa.toFixed(1).replace(
                          ".",
                          ","
                        )
                      : props.row.FC_jogo_total_clube_fora.toFixed(1).replace(
                          ".",
                          ","
                        )
                  }}
                </b-table-column>

                <b-table-column
                  :field="'FC_DS_total_clube_' + props.row.mando"
                  :label="$mq == 'sm' ? 'FC/ DS' : 'FC / Desarmes'"
                  centered
                >
                  {{
                    props.row.mando == "casa"
                      ? props.row.FC_DS_total_clube_casa.toFixed(1).replace(
                          ".",
                          ","
                        )
                      : props.row.FC_DS_total_clube_fora.toFixed(1).replace(
                          ".",
                          ","
                        )
                  }}
                </b-table-column>

                <b-table-column
                  :field="'PT_jogo_total_clube_' + props.row.mando"
                  :label="$mq == 'sm' ? 'Pen./ jogo' : 'Pênaltis / jogo'"
                  centered
                >
                  {{
                    props.row.mando == "casa"
                      ? props.row.PT_jogo_total_clube_casa.toFixed(1).replace(
                          ".",
                          ","
                        )
                      : props.row.PT_jogo_total_clube_fora.toFixed(1).replace(
                          ".",
                          ","
                        )
                  }}
                </b-table-column>

                <b-table-column
                  :field="'CA_jogo_total_clube_' + props.row.mando"
                  :label="$mq == 'sm' ? 'CA/ jogo' : 'Média CA'"
                  centered
                >
                  {{
                    props.row.mando == "casa"
                      ? props.row.CA_jogo_total_clube_casa.toFixed(1).replace(
                          ".",
                          ","
                        )
                      : props.row.CA_jogo_total_clube_fora.toFixed(1).replace(
                          ".",
                          ","
                        )
                  }}
                </b-table-column>

                <b-table-column
                  :field="'CA_total_clube_' + props.row.mando"
                  label="CA"
                  centered
                >
                  {{
                    props.row.mando == "casa"
                      ? props.row.CA_total_clube_casa
                      : props.row.CA_total_clube_fora
                  }}
                </b-table-column>

                <b-table-column
                  :field="'CV_jogo_total_clube_' + props.row.mando"
                  :label="$mq == 'sm' ? 'CV/ jogo' : 'Média CV'"
                  centered
                >
                  {{
                    props.row.mando == "casa"
                      ? props.row.CV_jogo_total_clube_casa.toFixed(1).replace(
                          ".",
                          ","
                        )
                      : props.row.CV_jogo_total_clube_fora.toFixed(1).replace(
                          ".",
                          ","
                        )
                  }}
                </b-table-column>

                <b-table-column
                  :field="'CV_total_clube_' + props.row.mando"
                  label="CV"
                  centered
                >
                  {{
                    props.row.mando == "casa"
                      ? props.row.CV_total_clube_casa
                      : props.row.CV_total_clube_fora
                  }}
                </b-table-column>
                <!-- 
              <b-table-column label="Partida" centered>
                <div v-if="props.row.partida" class="level is-mobile" style="padding-top: 0 !important;">
                  <div class="level-item">
                    <div class="emb-team centralize">
                      <div>
                        <img :src="props.row.escudo_clube_casa" width="35" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="level-item">
                    <b>X</b>
                  </div>
                  <div class="level-item"> 
                    <div class="emb-team centralize">
                      <div>
                        <img :src="props.row.escudo_clube_fora" width="35" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <span v-else style="opacity: 0.2">
                  Sem Partida
                </span>
              </b-table-column>
 -->
              </template>

              <template slot="detail" slot-scope="props">
                <div v-if="$mq == 'sm'" class="columns is-gapless is-mobile">
                  <div class="column is-10">
                    <b-table
                      :id="
                        'partida-historico-mobile' + (props.row.mando == 'casa')
                          ? props.row.id_clube_casa
                          : props.row.id_clube_fora
                      "
                      :key="
                        'partida-historico-mobile' + (props.row.mando == 'casa')
                          ? props.row.id_clube_casa
                          : props.row.id_clube_fora
                      "
                      :show-header="false"
                      :data="props.row.historico"
                      sticky-header
                      height="auto"
                      :mobile-cards="false"
                    >
                      <template slot-scope="hist">
                        <b-table-column centered>
                          <div class="row-historico">{{ hist.row.diaMes }}</div>
                          <div class="row-historico">{{ hist.row.ano }}</div>
                        </b-table-column>

                        <b-table-column centered>
                          <div class="columns is-gapless is-mobile">
                            <div class="column is-6 has-background-dark">
                              <div class="placar">{{ hist.row.G_casa }}</div>
                              <div class="placar">{{ hist.row.G_vis }}</div>
                            </div>
                            <div class="column is-3"></div>
                            <div class="column is-8">
                              <div class="row-historico">
                                {{ hist.row.cd_clube_casa }}
                              </div>
                              <div class="row-historico">
                                {{ hist.row.cd_clube_vis }}
                              </div>
                            </div>
                          </div>
                        </b-table-column>

                        <b-table-column centered>
                          <div class="row-historico-numero">
                            {{ hist.row.FC_casa }} FC
                          </div>
                          <div class="row-historico-numero">
                            {{ hist.row.FC_vis }} FC
                          </div>
                        </b-table-column>

                        <b-table-column v-if="$mq !== 'sm'" centered>
                          <div class="row-historico-numero">
                            {{
                              (hist.row.FC_casa / hist.row.DS_casa)
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            FC/DS
                          </div>
                          <div class="row-historico-numero">
                            {{
                              (hist.row.FC_vis / hist.row.DS_vis)
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            FC/DS
                          </div>
                        </b-table-column>

                        <b-table-column centered>
                          <div class="row-historico-numero">
                            {{ hist.row.penalti_casa }} Pen
                          </div>
                          <div class="row-historico-numero">
                            {{ hist.row.penalti_vis }} Pen
                          </div>
                        </b-table-column>

                        <b-table-column centered>
                          <p>
                            <b-tag
                              type="is-warning"
                              size="is-small"
                              class="cartao"
                              >{{ hist.row.CA_casa }}</b-tag
                            >
                          </p>
                          <p>
                            <b-tag
                              type="is-warning"
                              size="is-small"
                              class="cartao"
                              >{{ hist.row.CA_vis }}</b-tag
                            >
                          </p>
                        </b-table-column>

                        <b-table-column centered>
                          <p>
                            <b-tag
                              type="is-danger"
                              size="is-small"
                              class="cartao"
                              >{{ hist.row.CV_casa }}</b-tag
                            >
                          </p>
                          <p>
                            <b-tag
                              type="is-danger"
                              size="is-small"
                              class="cartao"
                              >{{ hist.row.CV_vis }}</b-tag
                            >
                          </p>
                        </b-table-column>
                      </template>

                      <template slot="empty">
                        <section class="section">
                          <div class="content has-text-grey has-text-centered">
                            <p>
                              <b-icon icon="emoticon-sad" size="is-large">
                              </b-icon>
                            </p>
                            <p>
                              Árbitro ainda não foi escalado em nenhuma partida
                            </p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </div>
                </div>

                <b-table
                  v-else
                  :id="
                    'partida-historico-desk' + (props.row.mando == 'casa')
                      ? props.row.id_clube_casa
                      : props.row.id_clube_fora
                  "
                  :key="
                    'partida-historico-desk' + (props.row.mando == 'casa')
                      ? props.row.id_clube_casa
                      : props.row.id_clube_fora
                  "
                  :data="props.row.historico"
                  sticky-header
                  height="auto"
                  :mobile-cards="false"
                >
                  <template slot-scope="props" slot="header">
                    <div class="label-table">
                      <p v-html="props.column.label"></p>
                    </div>
                  </template>

                  <template slot-scope="hist">
                    <b-table-column
                      label="Data Partida"
                      centered
                      :sticky="true"
                    >
                      {{ hist.row.data }}
                    </b-table-column>

                    <b-table-column label="FC<br>(casa)" centered>
                      {{ hist.row.FC_casa }}
                    </b-table-column>

                    <b-table-column label="FC/DE<br>(casa)" centered>
                      {{
                        (hist.row.FC_casa / hist.row.DS_casa)
                          .toFixed(1)
                          .replace(".", ",")
                      }}
                    </b-table-column>

                    <b-table-column label="Pênalti<br>(casa)" centered>
                      {{ hist.row.penalti_casa }}
                    </b-table-column>

                    <b-table-column label="CV<br>(casa)" centered>
                      <b-tag type="is-danger" size="is-medium" class="cartao">{{
                        hist.row.CV_casa
                      }}</b-tag>
                    </b-table-column>

                    <b-table-column label="CA<br>(casa)" centered>
                      <b-tag
                        type="is-warning"
                        size="is-medium"
                        class="cartao"
                        >{{ hist.row.CA_casa }}</b-tag
                      >
                    </b-table-column>

                    <b-table-column label="Confronto" width="200" centered>
                      <div
                        class="level is-mobile has-background-dark"
                        style="padding: 5px !important; border-radius: 10px;"
                      >
                        <div class="level-item">
                          <div class="emb-team centralize">
                            <div>
                              <img
                                :src="hist.row.escudo_m_clube_casa"
                                width="35"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="level-item">
                          <b>{{ hist.row.G_casa }}</b>
                        </div>
                        <div class="level-item">
                          <b>X</b>
                        </div>
                        <div class="level-item">
                          <b>{{ hist.row.G_vis }}</b>
                        </div>
                        <div class="level-item">
                          <div class="emb-team centralize">
                            <div>
                              <img
                                :src="hist.row.escudo_m_clube_vis"
                                width="35"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-table-column>

                    <b-table-column label="CA<br>(fora)" centered>
                      <b-tag
                        type="is-warning"
                        size="is-medium"
                        class="cartao"
                        >{{ hist.row.CA_vis }}</b-tag
                      >
                    </b-table-column>

                    <b-table-column label="CV<br>(fora)" centered>
                      <b-tag type="is-danger" size="is-medium" class="cartao">{{
                        hist.row.CV_vis
                      }}</b-tag>
                    </b-table-column>

                    <b-table-column label="Pênalti<br>(fora)" centered>
                      {{ hist.row.penalti_vis }}
                    </b-table-column>

                    <b-table-column label="FC/DE<br>(fora)" centered>
                      {{
                        (hist.row.FC_vis / hist.row.DS_vis)
                          .toFixed(1)
                          .replace(".", ",")
                      }}
                    </b-table-column>

                    <b-table-column label="FC<br>(fora)" centered>
                      {{ hist.row.FC_vis }}
                    </b-table-column>
                  </template>

                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                        </p>
                        <p>Clube não jogou nas rodadas anteriores</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Árbitro não escalado para a próxima rodada</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>

        <div class="column is-full">
          <div class="block-content-details has-background-dark">
            <h3 class="title" style="margin-top: 0; margin-bottom: 1em">
              Histórico do Árbitro por Clube
            </h3>

            <b-table
              id="arbitro"
              key="arbitro"
              :data="histArbitro"
              detailed
              sticky-header
              height="auto"
              :mobile-cards="false"
              default-sort="FC_jogo"
              default-sort-direction="desc"
            >
              <template slot-scope="props">
                <b-table-column
                  :field="'nm_clube_' + props.row.mando"
                  label="Clube"
                  :sticky="true"
                >
                  <div v-if="$mq == 'sm'" style="width: max-content;">
                    <span v-if="props.row.cd_clube">
                      {{ props.row.cd_clube }}
                    </span>
                    <span v-else>
                      {{ props.row.nm_clube }}
                    </span>
                  </div>
                  <div v-else style="width: max-content;">
                    <div style="display: inline-table; vertical-align: middle;">
                      <img :src="props.row.escudo_clube" width="35" alt="" />
                    </div>
                    <div
                      style="display: inline-table; vertical-align: middle; margin-left: 5px;"
                    >
                      {{ props.row.nm_clube }}
                    </div>
                  </div>
                </b-table-column>

                <b-table-column field="partidas" label="Jogos" centered>
                  {{ props.row.partidas }}
                </b-table-column>

                <b-table-column
                  field="FC_jogo"
                  :label="$mq == 'sm' ? 'FC/ jogo' : 'FC / jogo'"
                  centered
                >
                  {{ props.row.FC_jogo.toFixed(1).replace(".", ",") }}
                </b-table-column>

                <b-table-column
                  field="FC_DS"
                  :label="$mq == 'sm' ? 'FC/ DS' : 'FC / Desarmes'"
                  centered
                >
                  {{ props.row.FC_DS.toFixed(1).replace(".", ",") }}
                </b-table-column>

                <b-table-column
                  field="penalti_jogo"
                  :label="$mq == 'sm' ? 'Pen./ jogo' : 'Pênaltis / jogo'"
                  centered
                >
                  {{ props.row.penalti_jogo.toFixed(1).replace(".", ",") }}
                </b-table-column>

                <b-table-column
                  field="CA_jogo"
                  :label="$mq == 'sm' ? 'CA/ jogo' : 'Média CA'"
                  centered
                >
                  {{ props.row.CA_jogo.toFixed(1).replace(".", ",") }}
                </b-table-column>

                <b-table-column field="CA" label="CA" centered>
                  {{ props.row.CA }}
                </b-table-column>

                <b-table-column
                  field="CV_jogo"
                  :label="$mq == 'sm' ? 'CV/ jogo' : 'Média CV'"
                  centered
                >
                  {{ props.row.CV_jogo.toFixed(1).replace(".", ",") }}
                </b-table-column>

                <b-table-column field="CV" label="CV" centered>
                  {{ props.row.CV }}
                </b-table-column>
                <!-- 
              <b-table-column label="Partida" centered>
                <div v-if="props.row.partida" class="level is-mobile" style="padding-top: 0 !important;">
                  <div class="level-item">
                    <div class="emb-team centralize">
                      <div>
                        <img :src="props.row.escudo_clube_casa" width="35" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="level-item">
                    <b>X</b>
                  </div>
                  <div class="level-item"> 
                    <div class="emb-team centralize">
                      <div>
                        <img :src="props.row.escudo_clube_fora" width="35" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <span v-else style="opacity: 0.2">
                  Sem Partida
                </span>
              </b-table-column>
 -->
              </template>

              <template slot="detail" slot-scope="props">
                <div v-if="$mq == 'sm'" class="columns is-gapless is-mobile">
                  <div class="column is-10">
                    <b-table
                      :id="'arbitro-historico-mobile' + props.row.id_clube"
                      :key="'arbitro-historico-mobile' + props.row.id_clube"
                      :show-header="false"
                      :data="props.row.historico"
                      sticky-header
                      height="auto"
                      :mobile-cards="false"
                    >
                      <template slot-scope="hist">
                        <b-table-column centered>
                          <div class="row-historico">{{ hist.row.diaMes }}</div>
                          <div class="row-historico">{{ hist.row.ano }}</div>
                        </b-table-column>

                        <b-table-column centered>
                          <div class="columns is-gapless is-mobile">
                            <div class="column is-6 has-background-dark">
                              <div class="placar">{{ hist.row.G_casa }}</div>
                              <div class="placar">{{ hist.row.G_vis }}</div>
                            </div>
                            <div class="column is-3"></div>
                            <div class="column is-8">
                              <div class="row-historico">
                                {{ hist.row.cd_clube_casa }}
                              </div>
                              <div class="row-historico">
                                {{ hist.row.cd_clube_vis }}
                              </div>
                            </div>
                          </div>
                        </b-table-column>

                        <b-table-column centered>
                          <div class="row-historico-numero">
                            {{ hist.row.FC_casa }} FC
                          </div>
                          <div class="row-historico-numero">
                            {{ hist.row.FC_vis }} FC
                          </div>
                        </b-table-column>

                        <b-table-column v-if="$mq !== 'sm'" centered>
                          <div class="row-historico-numero">
                            {{
                              (hist.row.FC_casa / hist.row.DS_casa)
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            FC/DS
                          </div>
                          <div class="row-historico-numero">
                            {{
                              (hist.row.FC_vis / hist.row.DS_vis)
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            FC/DS
                          </div>
                        </b-table-column>

                        <b-table-column centered>
                          <div class="row-historico-numero">
                            {{ hist.row.penalti_casa }} Pen
                          </div>
                          <div class="row-historico-numero">
                            {{ hist.row.penalti_vis }} Pen
                          </div>
                        </b-table-column>

                        <b-table-column centered>
                          <p>
                            <b-tag
                              type="is-warning"
                              size="is-small"
                              class="cartao"
                              >{{ hist.row.CA_casa }}</b-tag
                            >
                          </p>
                          <p>
                            <b-tag
                              type="is-warning"
                              size="is-small"
                              class="cartao"
                              >{{ hist.row.CA_vis }}</b-tag
                            >
                          </p>
                        </b-table-column>

                        <b-table-column centered>
                          <p>
                            <b-tag
                              type="is-danger"
                              size="is-small"
                              class="cartao"
                              >{{ hist.row.CV_casa }}</b-tag
                            >
                          </p>
                          <p>
                            <b-tag
                              type="is-danger"
                              size="is-small"
                              class="cartao"
                              >{{ hist.row.CV_vis }}</b-tag
                            >
                          </p>
                        </b-table-column>
                      </template>

                      <template slot="empty">
                        <section class="section">
                          <div class="content has-text-grey has-text-centered">
                            <p>
                              <b-icon icon="emoticon-sad" size="is-large">
                              </b-icon>
                            </p>
                            <p>
                              Árbitro ainda não foi escalado em nenhuma partida
                            </p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </div>
                </div>

                <b-table
                  v-else
                  :id="'arbitro-historico-desk' + props.row.id_clube"
                  :key="'arbitro-historico-desk' + props.row.id_clube"
                  :data="props.row.historico"
                  sticky-header
                  height="auto"
                  mobile-cards
                >
                  <template slot-scope="props" slot="header">
                    <div class="label-table">
                      <p v-html="props.column.label"></p>
                    </div>
                  </template>

                  <template slot-scope="hist">
                    <b-table-column label="Data Partida" centered>
                      {{ hist.row.data }}
                    </b-table-column>

                    <b-table-column label="FC<br>(casa)" centered>
                      {{ hist.row.FC_casa }}
                    </b-table-column>

                    <b-table-column label="FC/DE<br>(casa)" centered>
                      {{
                        (hist.row.FC_casa / hist.row.DS_casa)
                          .toFixed(1)
                          .replace(".", ",")
                      }}
                    </b-table-column>

                    <b-table-column label="Pênalti<br>(casa)" centered>
                      {{ hist.row.penalti_casa }}
                    </b-table-column>

                    <b-table-column label="CV<br>(casa)" centered>
                      <b-tag type="is-danger" size="is-medium" class="cartao">{{
                        hist.row.CV_casa
                      }}</b-tag>
                    </b-table-column>

                    <b-table-column label="CA<br>(casa)" centered>
                      <b-tag
                        type="is-warning"
                        size="is-medium"
                        class="cartao"
                        >{{ hist.row.CA_casa }}</b-tag
                      >
                    </b-table-column>

                    <b-table-column label="Confronto" width="200" centered>
                      <div
                        class="level is-mobile has-background-dark"
                        style="padding: 5px !important; border-radius: 10px;"
                      >
                        <div class="level-item">
                          <div class="emb-team centralize">
                            <div>
                              <img
                                :src="hist.row.escudo_m_clube_casa"
                                width="35"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="level-item">
                          <b>{{ hist.row.G_casa }}</b>
                        </div>
                        <div class="level-item">
                          <b>X</b>
                        </div>
                        <div class="level-item">
                          <b>{{ hist.row.G_vis }}</b>
                        </div>
                        <div class="level-item">
                          <div class="emb-team centralize">
                            <div>
                              <img
                                :src="hist.row.escudo_m_clube_vis"
                                width="35"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-table-column>

                    <b-table-column label="CA<br>(fora)" centered>
                      <b-tag
                        type="is-warning"
                        size="is-medium"
                        class="cartao"
                        >{{ hist.row.CA_vis }}</b-tag
                      >
                    </b-table-column>

                    <b-table-column label="CV<br>(fora)" centered>
                      <b-tag type="is-danger" size="is-medium" class="cartao">{{
                        hist.row.CV_vis
                      }}</b-tag>
                    </b-table-column>

                    <b-table-column label="Pênalti<br>(fora)" centered>
                      {{ hist.row.penalti_vis }}
                    </b-table-column>

                    <b-table-column label="FC/DE<br>(fora)" centered>
                      {{
                        (hist.row.FC_vis / hist.row.DS_vis)
                          .toFixed(1)
                          .replace(".", ",")
                      }}
                    </b-table-column>

                    <b-table-column label="FC<br>(fora)" centered>
                      {{ hist.row.FC_vis }}
                    </b-table-column>
                  </template>

                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                        </p>
                        <p>Clube não jogou nas rodadas anteriores</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Árbitro ainda não foi escalado em nenhuma partida</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>

        <div class="column is-full">
          <div class="block-content-details has-background-dark">
            <h3 class="title" style="margin-top: 0; margin-bottom: 1em">
              Últimas partidas
            </h3>

            <b-table
              v-if="$mq == 'sm'"
              id="historico-mobile"
              key="historico-mobile"
              :show-header="false"
              :data="histPartida"
              sticky-header
              height="auto"
              :mobile-cards="false"
            >
              <template slot-scope="hist">
                <b-table-column centered :sticky="true">
                  <div class="row-historico">{{ hist.row.diaMes }}</div>
                  <div class="row-historico">{{ hist.row.ano }}</div>
                </b-table-column>

                <b-table-column centered>
                  <div class="columns is-gapless is-mobile">
                    <div class="column is-6 has-background-dark">
                      <div class="placar">{{ hist.row.G_casa }}</div>
                      <div class="placar">{{ hist.row.G_vis }}</div>
                    </div>
                    <div class="column is-3"></div>
                    <div class="column is-8">
                      <div class="row-historico">
                        {{ hist.row.cd_clube_casa }}
                      </div>
                      <div class="row-historico">
                        {{ hist.row.cd_clube_vis }}
                      </div>
                    </div>
                  </div>
                </b-table-column>

                <b-table-column centered>
                  <div class="row-historico-numero">
                    {{ hist.row.FC_casa }} FC
                  </div>
                  <div class="row-historico-numero">
                    {{ hist.row.FC_vis }} FC
                  </div>
                </b-table-column>

                <b-table-column v-if="$mq !== 'sm'" centered>
                  <div class="row-historico-numero">
                    {{
                      (hist.row.FC_casa / hist.row.DS_casa)
                        .toFixed(1)
                        .replace(".", ",")
                    }}
                    FC/DS
                  </div>
                  <div class="row-historico-numero">
                    {{
                      (hist.row.FC_vis / hist.row.DS_vis)
                        .toFixed(1)
                        .replace(".", ",")
                    }}
                    FC/DS
                  </div>
                </b-table-column>

                <b-table-column centered>
                  <div class="row-historico-numero">
                    {{ hist.row.penalti_casa }} Pen
                  </div>
                  <div class="row-historico-numero">
                    {{ hist.row.penalti_vis }} Pen
                  </div>
                </b-table-column>

                <b-table-column centered>
                  <p>
                    <b-tag type="is-warning" size="is-small" class="cartao">{{
                      hist.row.CA_casa
                    }}</b-tag>
                  </p>
                  <p>
                    <b-tag type="is-warning" size="is-small" class="cartao">{{
                      hist.row.CA_vis
                    }}</b-tag>
                  </p>
                </b-table-column>

                <b-table-column centered>
                  <p>
                    <b-tag type="is-danger" size="is-small" class="cartao">{{
                      hist.row.CV_casa
                    }}</b-tag>
                  </p>
                  <p>
                    <b-tag type="is-danger" size="is-small" class="cartao">{{
                      hist.row.CV_vis
                    }}</b-tag>
                  </p>
                </b-table-column>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Árbitro ainda não foi escalado em nenhuma partida</p>
                  </div>
                </section>
              </template>
            </b-table>

            <b-table
              v-else
              id="historico-desk"
              key="historico-desk"
              :data="histPartida"
              sticky-header
              height="auto"
              :mobile-cards="false"
            >
              <template slot-scope="props" slot="header">
                <div class="label-table">
                  <p v-html="props.column.label"></p>
                </div>
              </template>

              <template slot-scope="hist">
                <b-table-column label="Data Partida" centered :sticky="true">
                  {{ hist.row.data }}
                </b-table-column>

                <b-table-column label="FC<br>(casa)" centered>
                  {{ hist.row.FC_casa }}
                </b-table-column>

                <b-table-column label="FC/DE<br>(casa)" centered>
                  {{
                    (hist.row.FC_casa / hist.row.DS_casa)
                      .toFixed(1)
                      .replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column label="Pênalti<br>(casa)" centered>
                  {{ hist.row.penalti_casa }}
                </b-table-column>

                <b-table-column label="CV<br>(casa)" centered>
                  <b-tag type="is-danger" size="is-medium" class="cartao">{{
                    hist.row.CV_casa
                  }}</b-tag>
                </b-table-column>

                <b-table-column label="CA<br>(casa)" centered>
                  <b-tag type="is-warning" size="is-medium" class="cartao">{{
                    hist.row.CA_casa
                  }}</b-tag>
                </b-table-column>

                <b-table-column label="Confronto" width="200" centered>
                  <div
                    class="level is-mobile has-background-dark"
                    style="padding: 5px !important; border-radius: 10px;"
                  >
                    <div class="level-item">
                      <div class="emb-team centralize">
                        <div>
                          <img
                            :src="hist.row.escudo_m_clube_casa"
                            width="35"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="level-item">
                      <b>{{ hist.row.G_casa }}</b>
                    </div>
                    <div class="level-item">
                      <b>X</b>
                    </div>
                    <div class="level-item">
                      <b>{{ hist.row.G_vis }}</b>
                    </div>
                    <div class="level-item">
                      <div class="emb-team centralize">
                        <div>
                          <img
                            :src="hist.row.escudo_m_clube_vis"
                            width="35"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-table-column>

                <b-table-column label="CA<br>(fora)" centered>
                  <b-tag type="is-warning" size="is-medium" class="cartao">{{
                    hist.row.CA_vis
                  }}</b-tag>
                </b-table-column>

                <b-table-column label="CV<br>(fora)" centered>
                  <b-tag type="is-danger" size="is-medium" class="cartao">{{
                    hist.row.CV_vis
                  }}</b-tag>
                </b-table-column>

                <b-table-column label="Pênalti<br>(fora)" centered>
                  {{ hist.row.penalti_vis }}
                </b-table-column>

                <b-table-column label="FC/DE<br>(fora)" centered>
                  {{
                    (hist.row.FC_vis / hist.row.DS_vis)
                      .toFixed(1)
                      .replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column label="FC<br>(fora)" centered>
                  {{ hist.row.FC_vis }}
                </b-table-column>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Árbitro ainda não foi escalado em nenhuma partida</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </BasePage>
  </BaseLayout>
</template>

<script>
export default {
  name: "Detalhes",
  data() {
    return {
      arbitro: "",
      apelido: "",
      partida: [],
      histArbitro: [],
      histPartida: [],
    };
  },
  async created() {
    this.setDefaultToBrazilChampionship();

    if ("slug" in this.$route.params) {
      let loader = this.$loading.show();

      this.arbitro = atob(this.$route.params.slug);

      let nome = this.arbitro.split(" ");

      this.apelido =
        nome[0].substring(0, 1) +
        "." +
        (nome.length > 1 ? " " + nome[nome.length - 1] : "");
      // this.apelido = nome[0] + ((nome.length > 1) ? " "+nome[nome.length-1] : '')

      let dataPartida = await this.getArbitroPartida({
        arbitro: this.arbitro,
      });
      let dataHist = await this.getArbitroHistorico({
        arbitro: this.arbitro,
      });

      let histClube = [];
      for (const dP of dataPartida) {
        histClube = await this.getArbitroHistorico({
          clube: dP.id_clube_casa,
        });
        this.partida.push({ mando: "casa", historico: histClube, ...dP });

        histClube = await this.getArbitroHistorico({
          clube: dP.id_clube_fora,
        });
        this.partida.push({ mando: "fora", historico: histClube, ...dP });
      }

      let histIdx = -1;
      dataHist.map((elm) => {
        this.histPartida.push({ ...elm });

        histIdx = this.histArbitro.findIndex(
          (i) => i.id_clube == elm.id_clube_casa
        );

        if (histIdx == -1) {
          this.histArbitro.push({
            id_clube: elm.id_clube_casa,
            cd_clube: elm.cd_clube_casa,
            nm_clube: elm.nm_clube_casa,
            escudo_clube: elm.escudo_m_clube_casa,
            partidas: 1,
            FC: elm.FC_casa,
            DE: elm.DS_casa,
            penalti: elm.penalti_casa,
            CA: elm.CA_casa,
            CV: elm.CV_casa,
            FC_jogo: elm.FC_casa,
            FC_DS: elm.FC_casa,
            penalti_jogo: elm.penalti_casa,
            CA_jogo: elm.CA_casa,
            CV_jogo: elm.CV_casa,
            historico: [elm],
          });
        } else {
          this.histArbitro[histIdx].partidas += 1;
          this.histArbitro[histIdx].FC += elm.FC_casa;
          this.histArbitro[histIdx].DS += elm.DS_casa;
          this.histArbitro[histIdx].penalti += elm.penalti_casa;
          this.histArbitro[histIdx].CA += elm.CA_casa;
          this.histArbitro[histIdx].CV += elm.CV_casa;

          (this.histArbitro[histIdx].FC_jogo =
            this.histArbitro[histIdx].FC / this.histArbitro[histIdx].partidas),
            (this.histArbitro[histIdx].FC_DS =
              this.histArbitro[histIdx].FC /
              (this.histArbitro[histIdx].DS > 0
                ? this.histArbitro[histIdx].DS
                : 1)),
            (this.histArbitro[histIdx].penalti_jogo =
              this.histArbitro[histIdx].penalti /
              this.histArbitro[histIdx].partidas),
            (this.histArbitro[histIdx].CA_jogo =
              this.histArbitro[histIdx].CA /
              this.histArbitro[histIdx].partidas),
            (this.histArbitro[histIdx].CV_jogo =
              this.histArbitro[histIdx].CV /
              this.histArbitro[histIdx].partidas),
            this.histArbitro[histIdx].historico.push(elm);
        }

        histIdx = this.histArbitro.findIndex(
          (i) => i.id_clube == elm.id_clube_vis
        );

        if (histIdx == -1) {
          this.histArbitro.push({
            id_clube: elm.id_clube_vis,
            cd_clube: elm.cd_clube_vis,
            nm_clube: elm.nm_clube_vis,
            escudo_clube: elm.escudo_m_clube_vis,
            partidas: 1,
            FC: elm.FC_vis,
            DE: elm.DS_vis,
            penalti: elm.penalti_vis,
            CA: elm.CA_vis,
            CV: elm.CV_vis,
            FC_jogo: elm.FC_vis,
            FC_DS: elm.FC_vis,
            penalti_jogo: elm.penalti_vis,
            CA_jogo: elm.CA_vis,
            CV_jogo: elm.CV_vis,
            historico: [elm],
          });
        } else {
          this.histArbitro[histIdx].partidas += 1;
          this.histArbitro[histIdx].FC += elm.FC_vis;
          this.histArbitro[histIdx].DS += elm.DS_vis;
          this.histArbitro[histIdx].penalti += elm.penalti_vis;
          this.histArbitro[histIdx].CA += elm.CA_vis;
          this.histArbitro[histIdx].CV += elm.CV_vis;

          (this.histArbitro[histIdx].FC_jogo =
            this.histArbitro[histIdx].FC / this.histArbitro[histIdx].partidas),
            (this.histArbitro[histIdx].FC_DS =
              this.histArbitro[histIdx].FC /
              (this.histArbitro[histIdx].DS > 0
                ? this.histArbitro[histIdx].DS
                : 1)),
            (this.histArbitro[histIdx].penalti_jogo =
              this.histArbitro[histIdx].penalti /
              this.histArbitro[histIdx].partidas),
            (this.histArbitro[histIdx].CA_jogo =
              this.histArbitro[histIdx].CA /
              this.histArbitro[histIdx].partidas),
            (this.histArbitro[histIdx].CV_jogo =
              this.histArbitro[histIdx].CV /
              this.histArbitro[histIdx].partidas),
            this.histArbitro[histIdx].historico.push(elm);
        }
      });

      loader.hide();
    } else {
      this.$router.push("/painel/arbitros");
    }
  },
  methods: {
    ...mapActions(["getArbitroPartida", "getArbitroHistorico"]),
  },
};
</script>

<style scoped>
#referee-details .block-content-details {
  width: 100%;
  padding: 1em;
  border-radius: 4px;
}
#referee-details .emb-user {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(204, 204, 204);
}
#referee-details .emb-user-1 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(204, 204, 204);
}
#referee-details .title-name {
  font-size: 1.8em;
  font-weight: bold;
}

#referee-details .cartao {
  padding: 20px 10px;
  font-weight: bolder;
}

@media screen and (max-width: 768px) {
  #referee-details .row-historico {
    display: block;
    padding: 5px 0px;
    width: max-content;
    text-align: center;
    width: 40px;
  }
  #referee-details .row-historico-numero {
    display: block;
    padding: 5px 0px;
    margin-left: 5px;
    width: 40px;
    text-align: right !important;
    float: right !important;
  }
  #referee-details .placar {
    padding: 5px 7px;
    border-radius: 10px;
    font-weight: bolder;
  }
  #referee-details .cartao {
    padding: 13px 7px;
    margin: 2px 0px;
    font-weight: bolder;
  }
}
</style>
