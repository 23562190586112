<template>
  <BaseLayout :championships="championships" is-page-for-default-championship>
    <BasePage id="referees" title="Árbitros">
      <div class="columns is-multiline">
        <div class="column">
          <b-navbar
            mobile-burger
            type="is-independence"
            style="margin-bottom: 0.5em; border-radius: 7px"
          >
            <template slot="start">
              <b-navbar-item
                tag="div"
                @click="viewTable = 'arbitros'"
                :active="viewTable == 'arbitros'"
                style="cursor: pointer"
              >
                <b :class="{ 'tag is-primary': viewTable == 'arbitros' }"
                  >Árbitros</b
                >
              </b-navbar-item>

              <b-navbar-item
                tag="div"
                @click="viewTable = 'clubes'"
                :active="viewTable == 'clubes'"
                style="cursor: pointer"
              >
                <b :class="{ 'tag is-primary': viewTable == 'clubes' }"
                  >Clubes</b
                >
              </b-navbar-item>
            </template>

            <template slot="end">
              <b-navbar-item
                v-if="viewTable == 'arbitros'"
                tag="div"
                @click="param.rodada = 'atual'"
                :active="param.rodada == 'atual'"
                style="cursor: pointer"
              >
                <b :class="{ 'tag is-primary': param.rodada == 'atual' }"
                  >Árbitros da Rodada</b
                >
              </b-navbar-item>

              <b-navbar-item
                v-if="viewTable == 'arbitros'"
                tag="div"
                @click="param.rodada = 'todos'"
                :active="param.rodada == 'todos'"
                style="cursor: pointer"
              >
                <b :class="{ 'tag is-primary': param.rodada == 'todos' }"
                  >Todos os Árbitros</b
                >
              </b-navbar-item>

              <b-navbar-item
                tag="div"
                @click="param.clubes = 'casa'"
                :active="param.clubes == 'casa'"
                style="cursor: pointer"
              >
                <b :class="{ 'tag is-primary': param.clubes == 'casa' }"
                  >Mandantes</b
                >
              </b-navbar-item>

              <b-navbar-item
                tag="div"
                @click="param.clubes = 'fora'"
                :active="param.clubes == 'fora'"
                style="cursor: pointer"
              >
                <b :class="{ 'tag is-primary': param.clubes == 'fora' }"
                  >Visitantes</b
                >
              </b-navbar-item>

              <b-navbar-item
                tag="div"
                @click="param.clubes = 'total'"
                :active="param.clubes == 'total'"
                style="cursor: pointer"
              >
                <b :class="{ 'tag is-primary': param.clubes == 'total' }"
                  >Todos os Mandos</b
                >
              </b-navbar-item>
            </template>
          </b-navbar>

          <div class="content-tb-ar" v-if="showTable">
            <b-table
              id="table-arbitros"
              key="table-arbitros"
              v-if="viewTable == 'arbitros'"
              :data="dataArbitros"
              blueDark
              detailed
              detail-key="arbitro"
              paginated
              :per-page="15"
              pagination-size="is-small"
              :mobile-cards="false"
              :loading="load"
              @click="(row) => clickRow(row)"
            >
              <!-- :opened-detailed="[dataArbitros[0].arbitro]" -->
              <template slot-scope="props" slot="header">
                <div v-if="props.column.sortable" class="label-table">
                  <span v-if="props.column.sortable">
                    <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
                  </span>
                  <p>{{ props.column.label }}</p>
                </div>
                <div v-else class="label-table">
                  <p>{{ props.column.label }}</p>
                </div>
              </template>

              <template slot-scope="props">
                <b-table-column
                  field="arbitro"
                  label="Nome"
                  header-class="has-background-defaultbg"
                  sortable
                >
                  <div
                    style="display: inline-flex; flex-direction: row; align-items: center"
                  >
                    <img
                      v-if="$mq !== 'sm'"
                      src="@/assets/icon-apito-branco.png"
                      width="30px"
                    />
                    <span
                      v-if="$mq == 'sm'"
                      style="cursor: pointer; margin-left: 5px;"
                      >{{ props.row.apelido }}</span
                    >
                    <span v-else style="cursor: pointer; margin-left: 5px;">{{
                      props.row.arbitro
                    }}</span>
                  </div>
                </b-table-column>

                <b-table-column
                  field="qtd_jogos"
                  label="Jogos"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{ props.row.qtd_jogos }}
                </b-table-column>

                <b-table-column
                  :field="'FC_jogo_' + param.clubes"
                  label="FC / jogo"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    param.clubes == "total"
                      ? props.row.FC_jogo_total.toFixed(1).replace(".", ",")
                      : param.clubes == "casa"
                      ? props.row.FC_jogo_casa.toFixed(1).replace(".", ",")
                      : props.row.FC_jogo_fora.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  :field="'FC_DS_' + param.clubes"
                  :label="$mq == 'sm' ? 'FC / DS' : 'FC / Desarmes'"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    param.clubes == "total"
                      ? props.row.FC_DS_total.toFixed(1).replace(".", ",")
                      : param.clubes == "casa"
                      ? props.row.FC_DS_casa.toFixed(1).replace(".", ",")
                      : props.row.FC_DS_fora.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  :field="'PT_jogo_' + param.clubes"
                  :label="$mq == 'sm' ? 'Pen. / jogo' : 'Pênaltis / Jogo'"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    param.clubes == "total"
                      ? props.row.PT_jogo_total.toFixed(1).replace(".", ",")
                      : param.clubes == "casa"
                      ? props.row.PT_jogo_casa.toFixed(1).replace(".", ",")
                      : props.row.PT_jogo_fora.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  :field="'CA_jogo_' + param.clubes"
                  :label="$mq == 'sm' ? 'CA / jogo' : 'Média de CA'"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    param.clubes == "total"
                      ? props.row.CA_jogo_total.toFixed(1).replace(".", ",")
                      : param.clubes == "casa"
                      ? props.row.CA_jogo_casa.toFixed(1).replace(".", ",")
                      : props.row.CA_jogo_fora.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  :field="'CA_' + param.clubes"
                  label="CA"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    param.clubes == "total"
                      ? props.row.CA_total
                      : param.clubes == "casa"
                      ? props.row.CA_casa
                      : props.row.CA_fora
                  }}
                </b-table-column>

                <b-table-column
                  :field="'CV_jogo_' + param.clubes"
                  :label="$mq == 'sm' ? 'CV / jogo' : 'Média de CV'"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    param.clubes == "total"
                      ? props.row.CV_jogo_total.toFixed(1).replace(".", ",")
                      : param.clubes == "casa"
                      ? props.row.CV_jogo_casa.toFixed(1).replace(".", ",")
                      : props.row.CV_jogo_fora.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  :field="'CV_' + param.clubes"
                  label="CV"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    param.clubes == "total"
                      ? props.row.CV_total
                      : param.clubes == "casa"
                      ? props.row.CV_casa
                      : props.row.CV_fora
                  }}
                </b-table-column>

                <b-table-column
                  label="Partida"
                  centered
                  header-class="has-background-defaultbg"
                >
                  <div
                    v-if="props.row.partida"
                    class="level is-mobile"
                    style="padding-top: 0 !important;"
                  >
                    <div class="level-item">
                      <div class="emb-team centralize">
                        <div>
                          <img
                            :src="props.row.escudo_clube_casa"
                            width="25"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="level-item">
                      <b>X</b>
                    </div>
                    <div class="level-item">
                      <div class="emb-team centralize">
                        <div>
                          <img
                            :src="props.row.escudo_clube_fora"
                            width="25"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span v-else style="opacity: 0.2">
                    Sem Partida
                  </span>
                </b-table-column>
              </template>

              <template slot="detail" slot-scope="props">
                <div
                  v-if="props.row.partida && $mq == 'sm'"
                  class="columns is-gapless is-multiline is-mobile has-text-white "
                >
                  <div class="column is-3 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA TOTAL</p>
                      <small>Árbitro x Clubes</small>
                    </div>
                    <div class="detail-column-content">
                      <div class="columns is-gapless is-multiline is-mobile">
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Média
                            </div>
                            <div class="column is-4">
                              <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                            </div>
                            <div class="column is-3">
                              <img
                                style="width: 25px !important;"
                                :src="props.row.escudo_clube_casa"
                                :alt="props.row.nm_clube_casa"
                              />
                            </div>
                            <div class="column is-3">
                              <img
                                style="width: 25px !important;"
                                :src="props.row.escudo_clube_fora"
                                :alt="props.row.nm_clube_fora"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_FC_jogo_total }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.FC_jogo_total_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.FC_jogo_total_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC/DE
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_FC_DS_total }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.FC_DS_total_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.FC_DS_total_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Pen.
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_PT_jogo_total }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.PT_jogo_total_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.PT_jogo_total_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CA
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_CA_jogo_total }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.CA_jogo_total_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.CA_jogo_total_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CV
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_CV_jogo_total }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.CV_jogo_total_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.CV_jogo_total_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="column is-4 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA CASA</p>
                      <small>Árbitro x Clube Mandante</small>
                    </div>
                    <div class="detail-column-content">
                      <div class="columns is-gapless is-multiline is-mobile">
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Média
                            </div>
                            <div class="column is-4">
                              <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                            </div>
                            <div class="column is-2">
                              <img
                                style="width: 25px !important;"
                                :src="props.row.escudo_clube_casa"
                                :alt="props.row.nm_clube_casa"
                              />
                            </div>
                            <div class="column is-4">
                              <div
                                class="columns is-mobile is-centered is-vcentered is-gapless"
                              >
                                <div class="column is-narrow">
                                  <img
                                    src="@/assets/icon-apito-branco.png"
                                    width="25px"
                                  />
                                </div>
                                <div class="column is-2">
                                  <span>X</span>
                                </div>
                                <div class="column is-narrow">
                                  <img
                                    style="width: 25px;"
                                    :src="props.row.escudo_clube_casa"
                                    :alt="props.row.nm_clube_casa"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_FC_jogo_casa }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.FC_jogo_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC/DE
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_FC_DS_casa }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.FC_DS_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Pen.
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_PT_jogo_casa }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.PT_jogo_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CA
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_CA_jogo_casa }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.CA_jogo_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CV
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_CV_jogo_casa }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.CV_jogo_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="column is-4 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA FORA</p>
                      <small>Árbitro x Clube Visitante</small>
                    </div>
                    <div class="detail-column-content">
                      <div class="columns is-gapless is-multiline is-mobile">
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Média
                            </div>
                            <div class="column is-4">
                              <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                            </div>
                            <div class="column is-2">
                              <img
                                style="width: 25px !important;"
                                :src="props.row.escudo_clube_fora"
                                :alt="props.row.nm_clube_fora"
                              />
                            </div>
                            <div class="column is-4">
                              <div
                                class="columns is-mobile is-centered is-vcentered is-gapless"
                              >
                                <div class="column is-narrow">
                                  <img
                                    src="@/assets/icon-apito-branco.png"
                                    width="25px"
                                  />
                                </div>
                                <div class="column is-2">
                                  <span>X</span>
                                </div>
                                <div class="column is-narrow">
                                  <img
                                    style="width: 25px;"
                                    :src="props.row.escudo_clube_fora"
                                    :alt="props.row.nm_clube_fora"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_FC_jogo_fora }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.FC_jogo_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC/DE
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_FC_DS_fora }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.FC_DS_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Pen.
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_PT_jogo_fora }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.PT_jogo_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CA
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_CA_jogo_fora }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.CA_jogo_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CV
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}<b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{ props.row.rnk_CV_jogo_fora }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.CV_jogo_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="props.row.partida && $mq != 'sm'"
                  class="columns is-gapless has-text-white"
                >
                  <div class="column is-3 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA TOTAL</p>
                      <small>Árbitro x Clubes</small>
                    </div>
                    <div class="detail-column-content">
                      <table>
                        <tr>
                          <td></td>
                          <td>
                            <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                          </td>
                          <td>
                            <img
                              style="width: 30px;"
                              :src="props.row.escudo_clube_casa"
                              :alt="props.row.nm_clube_casa"
                            />
                          </td>
                          <td>
                            <img
                              style="width: 30px;"
                              :src="props.row.escudo_clube_fora"
                              :alt="props.row.nm_clube_fora"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>FC/Jogo</td>
                          <td>
                            {{
                              props.row.FC_jogo_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_FC_jogo_total }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_total_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_total_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>FC/DE</td>
                          <td>
                            {{
                              props.row.FC_DS_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_FC_DS_total }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_total_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_total_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Pênalti</td>
                          <td>
                            {{
                              props.row.PT_jogo_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_PT_jogo_total }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_total_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_total_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CA</td>
                          <td>
                            {{
                              props.row.CA_jogo_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_CA_jogo_total }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_total_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_total_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CV</td>
                          <td>
                            {{
                              props.row.CV_jogo_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_CV_jogo_total }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_total_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_total_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="column is-4 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA CASA</p>
                      <small>Árbitro x Clube Mandante</small>
                    </div>
                    <div class="detail-column-content">
                      <table>
                        <tr>
                          <td></td>
                          <td>
                            <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                          </td>
                          <td>
                            <img
                              style="width: 30px;"
                              :src="props.row.escudo_clube_casa"
                              :alt="props.row.nm_clube_casa"
                            />
                          </td>
                          <td>
                            <div
                              class="columns is-centered is-vcentered is-gapless"
                            >
                              <div class="column is-narrow">
                                <img
                                  src="@/assets/icon-apito-branco.png"
                                  width="30px"
                                />
                              </div>
                              <div class="column is-2">
                                <span>X</span>
                              </div>
                              <div class="column is-narrow">
                                <img
                                  style="width: 30px;"
                                  :src="props.row.escudo_clube_casa"
                                  :alt="props.row.nm_clube_casa"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>FC/Jogo</td>
                          <td>
                            {{
                              props.row.FC_jogo_casa.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_FC_jogo_casa }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>FC/DE</td>
                          <td>
                            {{
                              props.row.FC_DS_casa.toFixed(1).replace(".", ",")
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_FC_DS_casa }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Pênalti</td>
                          <td>
                            {{
                              props.row.PT_jogo_casa.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_PT_jogo_casa }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CA</td>
                          <td>
                            {{
                              props.row.CA_jogo_casa.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_CA_jogo_casa }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CV</td>
                          <td>
                            {{
                              props.row.CV_jogo_casa.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_CV_jogo_casa }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="column is-4 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA FORA</p>
                      <small>Árbitro x Clube Visitante</small>
                    </div>
                    <div class="detail-column-content">
                      <table>
                        <tr>
                          <td></td>
                          <td>
                            <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                          </td>
                          <td>
                            <img
                              style="width: 30px;"
                              :src="props.row.escudo_clube_fora"
                              :alt="props.row.nm_clube_fora"
                            />
                          </td>
                          <td>
                            <div
                              class="columns is-centered is-vcentered is-gapless"
                            >
                              <div class="column is-narrow">
                                <img
                                  src="@/assets/icon-apito-branco.png"
                                  width="30px"
                                />
                              </div>
                              <div class="column is-2">
                                <span>X</span>
                              </div>
                              <div class="column is-narrow">
                                <img
                                  style="width: 30px;"
                                  :src="props.row.escudo_clube_fora"
                                  :alt="props.row.nm_clube_fora"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>FC/Jogo</td>
                          <td>
                            {{
                              props.row.FC_jogo_fora.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_FC_jogo_fora }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>FC/DE</td>
                          <td>
                            {{
                              props.row.FC_DS_fora.toFixed(1).replace(".", ",")
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_FC_DS_fora }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Pênalti</td>
                          <td>
                            {{
                              props.row.PT_jogo_fora.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_PT_jogo_fora }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CA</td>
                          <td>
                            {{
                              props.row.CA_jogo_fora.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_CA_jogo_fora }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CV</td>
                          <td>
                            {{
                              props.row.CV_jogo_fora.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}<b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_CV_jogo_fora }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div v-if="!props.row.partida">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Estou de folga!</p>
                    </div>
                  </section>
                </div>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>

            <b-table
              id="table-clubes"
              key="table-clubes"
              v-if="viewTable == 'clubes'"
              :data="dataClubes"
              detailed
              sticky-header
              height="60vh"
              :mobile-cards="false"
              :loading="load"
            >
              <!-- paginated
            :per-page="15"
            pagination-size="is-small" -->

              <template slot-scope="props" slot="header">
                <div v-if="props.column.sortable" class="label-table">
                  <span v-if="props.column.sortable">
                    <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
                  </span>
                  <p>{{ props.column.label }}</p>
                </div>
                <div v-else class="label-table">
                  <p>{{ props.column.label }}</p>
                </div>
              </template>

              <template slot-scope="props">
                <b-table-column
                  field="nm_clube"
                  label="Clube"
                  :sticky="true"
                  header-class="has-background-defaultbg"
                  sortable
                >
                  <div style="width: max-content;">
                    <div style="display: inline-table; vertical-align: middle;">
                      <img :src="props.row.escudo_clube" width="30" alt="" />
                    </div>
                    <div
                      style="display: inline-table; vertical-align: middle; margin-left: 5px;"
                    >
                      {{ props.row.nm_clube }}
                    </div>
                  </div>
                </b-table-column>

                <b-table-column
                  field="qtd_jogos_total_clube"
                  label="Jogos"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{ props.row.qtd_jogos_total_clube }}
                </b-table-column>

                <b-table-column
                  field="FC_jogo_total_clube"
                  label="FC / Jogo"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    props.row.FC_jogo_total_clube.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  field="FC_DS_total_clube"
                  :label="$mq == 'sm' ? 'FC / DS' : 'FC / Desarmes'"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{ props.row.FC_DS_total_clube.toFixed(1).replace(".", ",") }}
                </b-table-column>

                <b-table-column
                  field="PT_jogo_total_clube"
                  :label="$mq == 'sm' ? 'Pen. / jogo' : 'Pênaltis / Jogo'"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    props.row.PT_jogo_total_clube.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  field="CA_jogo_total_clube"
                  :label="$mq == 'sm' ? 'CA / jogo' : 'Média de CA'"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    props.row.CA_jogo_total_clube.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  field="CA_total_clube"
                  label="CA"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{ props.row.CA_total_clube }}
                </b-table-column>

                <b-table-column
                  field="CV_jogo_total_clube"
                  :label="$mq == 'sm' ? 'CV / jogo' : 'Média de CV'"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{
                    props.row.CV_jogo_total_clube.toFixed(1).replace(".", ",")
                  }}
                </b-table-column>

                <b-table-column
                  field="CV_total_clube"
                  label="CV"
                  centered
                  header-class="has-background-defaultbg"
                  sortable
                >
                  {{ props.row.CV_total_clube }}
                </b-table-column>

                <b-table-column
                  label="Partida"
                  centered
                  header-class="has-background-defaultbg"
                >
                  <div
                    v-if="props.row.partida"
                    class="level is-mobile"
                    style="padding-top: 0 !important;"
                  >
                    <div class="level-item">
                      <div class="emb-team centralize">
                        <div>
                          <img
                            :src="props.row.escudo_clube_casa"
                            width="25"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="level-item">
                      <b>X</b>
                    </div>
                    <div class="level-item">
                      <div class="emb-team centralize">
                        <div>
                          <img
                            :src="props.row.escudo_clube_fora"
                            width="25"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span v-else style="opacity: 0.2">
                    Sem Partida
                  </span>
                </b-table-column>
              </template>

              <template slot="detail" slot-scope="props">
                <div
                  v-if="props.row.partida && $mq == 'sm'"
                  class="columns is-gapless is-multiline is-mobile has-text-white "
                >
                  <div class="column is-3 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA TOTAL</p>
                      <small>Árbitro x Clubes</small>
                    </div>
                    <div class="detail-column-content">
                      <div class="columns is-gapless is-multiline is-mobile">
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Média
                            </div>
                            <div class="column is-4">
                              <img
                                v-if="props.row.mando == 'casa'"
                                style="width: 30px;"
                                :src="props.row.escudo_clube_casa"
                                :alt="props.row.nm_clube_casa"
                              />
                              <img
                                v-if="props.row.mando == 'fora'"
                                style="width: 30px;"
                                :src="props.row.escudo_clube_fora"
                                :alt="props.row.nm_clube_fora"
                              />
                            </div>
                            <div class="column is-3">
                              <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                            </div>
                            <div class="column is-3">
                              <img
                                v-if="props.row.mando == 'fora'"
                                style="width: 30px;"
                                :src="props.row.escudo_clube_casa"
                                :alt="props.row.nm_clube_casa"
                              />
                              <img
                                v-if="props.row.mando == 'casa'"
                                style="width: 30px;"
                                :src="props.row.escudo_clube_fora"
                                :alt="props.row.nm_clube_fora"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC
                            </div>
                            <div class="column is-4">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.FC_jogo_total_clube_casa
                                  : props.row.FC_jogo_total_clube_fora
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                              <b-tag
                                v-if="props.row.mando == 'casa'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_FC_jogo_total_clube_casa
                                }}º</b-tag
                              >
                              <b-tag
                                v-if="props.row.mando == 'fora'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_FC_jogo_total_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.FC_jogo_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.FC_jogo_total_clube_fora
                                  : props.row.FC_jogo_total_clube_casa
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC/DE
                            </div>
                            <div class="column is-4">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.FC_DS_total_clube_casa
                                  : props.row.FC_DS_total_clube_fora
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                              <b-tag
                                v-if="props.row.mando == 'casa'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_FC_DS_total_clube_casa
                                }}º</b-tag
                              >
                              <b-tag
                                v-if="props.row.mando == 'fora'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_FC_DS_total_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.FC_DS_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.FC_DS_total_clube_fora
                                  : props.row.FC_DS_total_clube_casa
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Pen.
                            </div>
                            <div class="column is-4">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.PT_jogo_total_clube_casa
                                  : props.row.PT_jogo_total_clube_fora
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                              <b-tag
                                v-if="props.row.mando == 'casa'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_PT_jogo_total_clube_casa
                                }}º</b-tag
                              >
                              <b-tag
                                v-if="props.row.mando == 'fora'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_PT_jogo_total_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.PT_jogo_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.PT_jogo_total_clube_fora
                                  : props.row.PT_jogo_total_clube_casa
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CA
                            </div>
                            <div class="column is-4">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.CA_jogo_total_clube_casa
                                  : props.row.CA_jogo_total_clube_fora
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                              <b-tag
                                v-if="props.row.mando == 'casa'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_CA_jogo_total_clube_casa
                                }}º</b-tag
                              >
                              <b-tag
                                v-if="props.row.mando == 'fora'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_CA_jogo_total_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.CA_jogo_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.CA_jogo_total_clube_fora
                                  : props.row.CA_jogo_total_clube_casa
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CV
                            </div>
                            <div class="column is-4">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.CV_jogo_total_clube_casa
                                  : props.row.CV_jogo_total_clube_fora
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                              <b-tag
                                v-if="props.row.mando == 'casa'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_CV_jogo_total_clube_casa
                                }}º</b-tag
                              >
                              <b-tag
                                v-if="props.row.mando == 'fora'"
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_CV_jogo_total_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-3">
                              {{
                                props.row.CV_jogo_total.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-3">
                              {{
                                (props.row.mando == "casa"
                                  ? props.row.CV_jogo_total_clube_fora
                                  : props.row.CV_jogo_total_clube_casa
                                )
                                  .toFixed(1)
                                  .replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="column is-4 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA CASA</p>
                      <small>Árbitro x Clube Mandante</small>
                    </div>
                    <div class="detail-column-content">
                      <div class="columns is-gapless is-multiline is-mobile">
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Média
                            </div>
                            <div class="column is-4">
                              <img
                                style="width: 30px;"
                                :src="props.row.escudo_clube_casa"
                                :alt="props.row.nm_clube_casa"
                              />
                            </div>
                            <div class="column is-2">
                              <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                            </div>
                            <div class="column is-4">
                              <div
                                class="columns is-mobile is-centered is-vcentered is-gapless"
                              >
                                <div class="column is-narrow">
                                  <img
                                    src="@/assets/icon-apito-branco.png"
                                    width="25px"
                                  />
                                </div>
                                <div class="column is-2">
                                  <span>X</span>
                                </div>
                                <div class="column is-narrow">
                                  <img
                                    style="width: 25px;"
                                    :src="props.row.escudo_clube_casa"
                                    :alt="props.row.nm_clube_casa"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_FC_jogo_casa_clube_casa
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.FC_jogo_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC/DE
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_FC_DS_casa_clube_casa
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.FC_DS_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Pen.
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_PT_jogo_casa_clube_casa
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.PT_jogo_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CA
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_CA_jogo_casa_clube_casa
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.CA_jogo_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CV
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_casa_clube_casa.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_CV_jogo_casa_clube_casa
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.CV_jogo_casa.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_total_clube_casa_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="column is-4 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA FORA</p>
                      <small>Árbitro x Clube Visitante</small>
                    </div>
                    <div class="detail-column-content">
                      <div class="columns is-gapless is-multiline is-mobile">
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Média
                            </div>
                            <div class="column is-4">
                              <img
                                style="width: 30px;"
                                :src="props.row.escudo_clube_fora"
                                :alt="props.row.nm_clube_fora"
                              />
                            </div>
                            <div class="column is-2">
                              <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                            </div>
                            <div class="column is-4">
                              <div
                                class="columns is-mobile is-centered is-vcentered is-gapless"
                              >
                                <div class="column is-narrow">
                                  <img
                                    src="@/assets/icon-apito-branco.png"
                                    width="25px"
                                  />
                                </div>
                                <div class="column is-2">
                                  <span>X</span>
                                </div>
                                <div class="column is-narrow">
                                  <img
                                    style="width: 25px;"
                                    :src="props.row.escudo_clube_fora"
                                    :alt="props.row.nm_clube_fora"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_FC_jogo_fora_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.FC_jogo_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_jogo_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              FC/DE
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_FC_DS_fora_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.FC_DS_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.FC_DS_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              Pen.
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_PT_jogo_fora_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.PT_jogo_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.PT_jogo_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CA
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_CA_jogo_fora_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.CA_jogo_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CA_jogo_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="column is-full">
                          <div class="columns is-mobile">
                            <div class="column is-2">
                              CV
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_fora_clube_fora.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                              <b-tag
                                type="is-primary ranking"
                                size="is-small"
                                rounded
                                >{{
                                  props.row.rnk_CV_jogo_fora_clube_fora
                                }}º</b-tag
                              >
                            </div>
                            <div class="column is-2">
                              {{
                                props.row.CV_jogo_fora.toFixed(1).replace(
                                  ".",
                                  ","
                                )
                              }}
                            </div>
                            <div class="column is-4">
                              {{
                                props.row.CV_jogo_total_clube_fora_arb.toFixed(
                                  1
                                ).replace(".", ",")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="props.row.partida && $mq != 'sm'"
                  class="columns is-gapless has-text-white"
                >
                  <div class="column is-3 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA TOTAL</p>
                      <small>Árbitro x Clubes</small>
                    </div>
                    <div class="detail-column-content">
                      <table>
                        <tr>
                          <td></td>
                          <td>
                            <img
                              v-if="props.row.mando == 'casa'"
                              style="width: 30px;"
                              :src="props.row.escudo_clube_casa"
                              :alt="props.row.nm_clube_casa"
                            />
                            <img
                              v-if="props.row.mando == 'fora'"
                              style="width: 30px;"
                              :src="props.row.escudo_clube_fora"
                              :alt="props.row.nm_clube_fora"
                            />
                          </td>
                          <td>
                            <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                          </td>
                          <td>
                            <img
                              v-if="props.row.mando == 'fora'"
                              style="width: 30px;"
                              :src="props.row.escudo_clube_casa"
                              :alt="props.row.nm_clube_casa"
                            />
                            <img
                              v-if="props.row.mando == 'casa'"
                              style="width: 30px;"
                              :src="props.row.escudo_clube_fora"
                              :alt="props.row.nm_clube_fora"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>FC/Jogo</td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.FC_jogo_total_clube_casa
                                : props.row.FC_jogo_total_clube_fora
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            <b-tag
                              v-if="props.row.mando == 'casa'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_FC_jogo_total_clube_casa
                              }}º</b-tag
                            >
                            <b-tag
                              v-if="props.row.mando == 'fora'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_FC_jogo_total_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.FC_jogo_total_clube_fora
                                : props.row.FC_jogo_total_clube_casa
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>FC/DE</td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.FC_DS_total_clube_casa
                                : props.row.FC_DS_total_clube_fora
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            <b-tag
                              v-if="props.row.mando == 'casa'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_FC_DS_total_clube_casa
                              }}º</b-tag
                            >
                            <b-tag
                              v-if="props.row.mando == 'fora'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_FC_DS_total_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_total.toFixed(1).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.FC_DS_total_clube_fora
                                : props.row.FC_DS_total_clube_casa
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Pênalti</td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.PT_jogo_total_clube_casa
                                : props.row.PT_jogo_total_clube_fora
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            <b-tag
                              v-if="props.row.mando == 'casa'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_PT_jogo_total_clube_casa
                              }}º</b-tag
                            >
                            <b-tag
                              v-if="props.row.mando == 'fora'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_PT_jogo_total_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.PT_jogo_total_clube_fora
                                : props.row.PT_jogo_total_clube_casa
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CA</td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.CA_jogo_total_clube_casa
                                : props.row.CA_jogo_total_clube_fora
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            <b-tag
                              v-if="props.row.mando == 'casa'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_CA_jogo_total_clube_casa
                              }}º</b-tag
                            >
                            <b-tag
                              v-if="props.row.mando == 'fora'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_CA_jogo_total_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.CA_jogo_total_clube_fora
                                : props.row.CA_jogo_total_clube_casa
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CV</td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.CV_jogo_total_clube_casa
                                : props.row.CV_jogo_total_clube_fora
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                            <b-tag
                              v-if="props.row.mando == 'casa'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_CV_jogo_total_clube_casa
                              }}º</b-tag
                            >
                            <b-tag
                              v-if="props.row.mando == 'fora'"
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_CV_jogo_total_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_total.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              (props.row.mando == "casa"
                                ? props.row.CV_jogo_total_clube_fora
                                : props.row.CV_jogo_total_clube_casa
                              )
                                .toFixed(1)
                                .replace(".", ",")
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="column is-4 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA CASA</p>
                      <small>Árbitro x Clube Mandante</small>
                    </div>
                    <div class="detail-column-content">
                      <table>
                        <tr>
                          <td></td>
                          <td>
                            <img
                              style="width: 30px;"
                              :src="props.row.escudo_clube_casa"
                              :alt="props.row.nm_clube_casa"
                            />
                          </td>
                          <td>
                            <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                          </td>
                          <td>
                            <div
                              class="columns is-centered is-vcentered is-gapless"
                            >
                              <div class="column is-narrow">
                                <img
                                  src="@/assets/icon-apito-branco.png"
                                  width="30px"
                                />
                              </div>
                              <div class="column is-2">
                                <span>X</span>
                              </div>
                              <div class="column is-narrow">
                                <img
                                  style="width: 30px;"
                                  :src="props.row.escudo_clube_casa"
                                  :alt="props.row.nm_clube_casa"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>FC/Jogo</td>
                          <td>
                            {{
                              props.row.FC_jogo_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_FC_jogo_casa_clube_casa
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_casa.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>FC/DE</td>
                          <td>
                            {{
                              props.row.FC_DS_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_FC_DS_casa_clube_casa }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_casa.toFixed(1).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Pênalti</td>
                          <td>
                            {{
                              props.row.PT_jogo_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_PT_jogo_casa_clube_casa
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_casa.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CA</td>
                          <td>
                            {{
                              props.row.CA_jogo_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_CA_jogo_casa_clube_casa
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_casa.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CV</td>
                          <td>
                            {{
                              props.row.CV_jogo_casa_clube_casa.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_CV_jogo_casa_clube_casa
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_casa.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_total_clube_casa_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="column is-4 has-background-dark detail-column">
                    <div class="detail-column-title">
                      <p>MÉDIA FORA</p>
                      <small>Árbitro x Clube Visitante</small>
                    </div>
                    <div class="detail-column-content">
                      <table>
                        <tr>
                          <td></td>
                          <td>
                            <img
                              style="width: 30px;"
                              :src="props.row.escudo_clube_fora"
                              :alt="props.row.nm_clube_fora"
                            />
                          </td>
                          <td>
                            <b>{{ props.row.arbitro.split(" ")[0] }}</b>
                          </td>
                          <td>
                            <div
                              class="columns is-centered is-vcentered is-gapless"
                            >
                              <div class="column is-narrow">
                                <img
                                  src="@/assets/icon-apito-branco.png"
                                  width="30px"
                                />
                              </div>
                              <div class="column is-2">
                                <span>X</span>
                              </div>
                              <div class="column is-narrow">
                                <img
                                  style="width: 30px;"
                                  :src="props.row.escudo_clube_fora"
                                  :alt="props.row.nm_clube_fora"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>FC/Jogo</td>
                          <td>
                            {{
                              props.row.FC_jogo_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_FC_jogo_fora_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_fora.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_jogo_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>FC/DE</td>
                          <td>
                            {{
                              props.row.FC_DS_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{ props.row.rnk_FC_DS_fora_clube_fora }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_fora.toFixed(1).replace(".", ",")
                            }}
                          </td>
                          <td>
                            {{
                              props.row.FC_DS_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Pênalti</td>
                          <td>
                            {{
                              props.row.PT_jogo_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_PT_jogo_fora_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_fora.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              props.row.PT_jogo_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CA</td>
                          <td>
                            {{
                              props.row.CA_jogo_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_CA_jogo_fora_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_fora.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CA_jogo_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>CV</td>
                          <td>
                            {{
                              props.row.CV_jogo_fora_clube_fora.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                            <b-tag
                              type="is-primary ranking"
                              size="is-small"
                              rounded
                              >{{
                                props.row.rnk_CV_jogo_fora_clube_fora
                              }}º</b-tag
                            >
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_fora.toFixed(1).replace(
                                ".",
                                ","
                              )
                            }}
                          </td>
                          <td>
                            {{
                              props.row.CV_jogo_total_clube_fora_arb.toFixed(
                                1
                              ).replace(".", ",")
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div v-if="!props.row.partida">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Estou de folga!</p>
                    </div>
                  </section>
                </div>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </BasePage>
  </BaseLayout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";

import UtilMixin from "@/_mixins/Util";
import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";

export default {
  name: "Referees",
  mixins: [championshipMixins, UtilMixin],
  components: {
    BaseLayout,
    BasePage,
  },
  data() {
    return {
      showTable: false,
      showClubes: false,
      data: [],
      dataArbitors: [],
      dataClubes: [],
      rodadaAtual: [],
      load: false,
      viewTable: "arbitros",
      param: {
        clubes: "total",
        rodada: "todos",
      },
    };
  },
  computed: {
    ...mapGetters(["mercado"]),
  },
  async created() {
    let loader = this.$loading.show();
    await this.loadChampionships();
    this.setDefaultToBrazilChampionship();

    this.data = await this.getArbitrosClubes();

    this.dataArbitros = [...this.data];

    this.rodadaAtual = this.data.filter((a) => a.rodada_atual == 1);

    this.rodadaAtual.map((c) => {
      this.dataClubes.push({
        mando: "casa",
        nm_clube: c.nm_clube_casa,
        escudo_clube: c.escudo_clube_casa,
        qtd_jogos_total_clube: c.qtd_jogos_total_clube_casa,
        FC_jogo_total_clube: c.FC_jogo_total_clube_casa,
        FC_DS_total_clube: c.FC_DS_total_clube_casa,
        PT_jogo_total_clube: c.PT_jogo_total_clube_casa,
        CA_jogo_total_clube: c.CA_jogo_total_clube_casa,
        CA_total_clube: c.CA_total_clube_casa,
        CV_jogo_total_clube: c.CV_jogo_total_clube_casa,
        CV_total_clube: c.CV_total_clube_casa,
        ...c,
      });
      this.dataClubes.push({
        mando: "fora",
        nm_clube: c.nm_clube_fora,
        escudo_clube: c.escudo_clube_fora,
        qtd_jogos_total_clube: c.qtd_jogos_total_clube_fora,
        FC_jogo_total_clube: c.FC_jogo_total_clube_fora,
        FC_DS_total_clube: c.FC_DS_total_clube_fora,
        PT_jogo_total_clube: c.PT_jogo_total_clube_fora,
        CA_jogo_total_clube: c.CA_jogo_total_clube_fora,
        CA_total_clube: c.CA_total_clube_fora,
        CV_jogo_total_clube: c.CV_jogo_total_clube_fora,
        CV_total_clube: c.CV_total_clube_fora,
        ...c,
      });
    });

    this.showTable = true;
    loader.hide();
  },
  watch: {
    "param.rodada": async function(n, o) {
      let arbitros =
        this.param.rodada == "atual"
          ? this.data.filter((a) => a.rodada_atual == 1)
          : this.data;
      this.dataArbitros = JSON.parse(JSON.stringify(arbitros));
    },
    "param.clubes": async function(n, o) {
      if (this.viewTable == "clubes") {
        let tableClubes = [];

        if (this.param.clubes !== "total") {
          this.rodadaAtual.map((c) => {
            tableClubes.push({
              mando: this.param.clubes,
              nm_clube: c["nm_clube_" + this.param.clubes],
              escudo_clube: c["escudo_clube_" + this.param.clubes],
              qtd_jogos_total_clube:
                c["qtd_jogos_total_clube_" + this.param.clubes],
              FC_jogo_total_clube:
                c["FC_jogo_total_clube_" + this.param.clubes],
              FC_DS_total_clube: c["FC_DS_total_clube_" + this.param.clubes],
              PT_jogo_total_clube:
                c["PT_jogo_total_clube_" + this.param.clubes],
              CA_jogo_total_clube:
                c["CA_jogo_total_clube_" + this.param.clubes],
              CA_total_clube: c["CA_total_clube_" + this.param.clubes],
              CV_jogo_total_clube:
                c["CV_jogo_total_clube_" + this.param.clubes],
              CV_total_clube: c["CV_total_clube_" + this.param.clubes],
              ...c,
            });
          });
        } else {
          this.rodadaAtual.map((c) => {
            tableClubes.push({
              mando: "casa",
              nm_clube: c.nm_clube_casa,
              escudo_clube: c.escudo_clube_casa,
              qtd_jogos_total_clube: c.qtd_jogos_total_clube_casa,
              FC_jogo_total_clube: c.FC_jogo_total_clube_casa,
              FC_DS_total_clube: c.FC_DS_total_clube_casa,
              PT_jogo_total_clube: c.PT_jogo_total_clube_casa,
              CA_jogo_total_clube: c.CA_jogo_total_clube_casa,
              CA_total_clube: c.CA_total_clube_casa,
              CV_jogo_total_clube: c.CV_jogo_total_clube_casa,
              CV_total_clube: c.CV_total_clube_casa,
              ...c,
            });
            tableClubes.push({
              mando: "fora",
              nm_clube: c.nm_clube_fora,
              escudo_clube: c.escudo_clube_fora,
              qtd_jogos_total_clube: c.qtd_jogos_total_clube_fora,
              FC_jogo_total_clube: c.FC_jogo_total_clube_fora,
              FC_DS_total_clube: c.FC_DS_total_clube_fora,
              PT_jogo_total_clube: c.PT_jogo_total_clube_fora,
              CA_jogo_total_clube: c.CA_jogo_total_clube_fora,
              CA_total_clube: c.CA_total_clube_fora,
              CV_jogo_total_clube: c.CV_jogo_total_clube_fora,
              CV_total_clube: c.CV_total_clube_fora,
              ...c,
            });
          });
        }

        this.dataClubes = [...tableClubes];
      }
    },
  },
  methods: {
    ...mapActions(["getArbitrosClubes"]),
    clickRow(row) {
      let slug = btoa(row.arbitro);
      this.$router.push("/painel/arbitros/" + slug);
    },
  },
};
</script>

<style scoped>
.row-details {
  padding: 0.6em !important;
}
.content-tb-ar {
  margin-top: 1em;
}
.bg-table-new {
  background-color: #494949;
  padding: 0px !important;
}
.block-filter-legend {
  padding: 0.5em 0;
  margin-top: 0.5em;
}
.emb-team {
  height: 35px;
  width: 35px;
  /* border-radius: 50%; */
  overflow: hidden;
  /* background-color: rgb(204, 204, 204); */
}
.emb-user {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(204, 204, 204);
}
.emb-user-3 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(204, 204, 204);
}
@media screen and (max-width: 768px) {
  .atl-legend {
    padding: 7px 0 !important;
  }
  .atl-legend h2 {
    font-size: 0.85rem !important;
  }
}
.group-opc-atl {
  padding: 10px 5px 5px 5px;
  width: 100%;
}
.group-opc-atl h3 {
  font-weight: bolder;
  text-transform: uppercase;
}
.group-opc-atl span {
  text-transform: uppercase;
  font-size: 0.75rem;
}

#referees .detail-column {
  border-radius: 10px;
  margin: 0px 5px;
  padding: 0px !important;
  text-align: center;
}
#referees .detail-column-title {
  border: 1px solid white;
  border-radius: 10px;
  width: auto;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  padding: 5px;
}
#referees .detail-column-content {
  width: auto;
  text-align: center;
  font-size: 0.9em;
  padding: 5px;
}
#referees .detail-column-content table {
  width: 100%;
}
#referees .detail-column-content th,
#referees .detail-column-content td {
  padding: 5px;
  text-align: center;
  background-color: transparent !important;
}
#referees .detail-column-content th {
  font-size: 1.1em;
}
#referees .detail-column-content p {
  padding: 5px;
}
#referees .ranking {
  font-weight: bolder !important;
  font-size: 0.9em !important;
  padding: 5px !important;
  margin-left: 8px !important;
}
</style>
